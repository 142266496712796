<template>
  <div>
    <b-card
      no-body
    >

      <div class="table-header m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-form-checkbox
              v-model="elementsPerPageCheckbox"
              class="mr-1 bulk-checkbox"
              @change="markElementsOnPage"
            />

            <label class="mr-1 mb-0">Show</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :value="perPage"
              :clearable="false"
              :reduce="option => option.value"
              class="per-page-selector d-inline-block ml-50 mr-1"
              @input="updatePerPage"
            />
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <label class="mr-1 mb-0">Search</label>
              <b-form-input
                v-model="searchQuery"
                placeholder="Search"
                class="w-25 mr-1"
              />
              <view-customizer />
              <applications-bulk-actions-buttons
                :marked-elements="markedElements"
                :is-right-align="true"
              />
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refApplicationsListTable"
        :items="fetchApplicationList"
        :fields="selectedViewColumns"
        sticky-header="75vh"
        primary-key="id"
        show-empty
        details-td-class="p-0"
        empty-text="No matching records found"
        :busy="isTableLoading"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        @sort-changed="sortingChanged"
        class="position-relative table-sticky-head"
        :style="{'minHeight': tableMinHeight}"
      >
        <!-- Loader -->
        <template #table-busy>
          <TableSpinner />
        </template>

        <template #row-details>
          <div />
        </template>

        <template #cell(selected)="data">
          <b-form-checkbox
            v-model="markedElements"
            :value="data.item.id"
            inline
            @change="handleElementsPerPageCheckbox"
          />
        </template>

        <!-- Column: name -->
        <template #cell(user_name)="data">
          <b-avatar
            class="mr-1"
            size="32"
            :src="'data.item.user.avatar'"
          />
          <b-link
            :to="{ name: 'admin-application-edit', params: { id: data.item.id, type: userRole } }"
          >{{ data.item.user_name }}</b-link>
        </template>

        <!-- Column: age -->
        <template #cell(age)="data">
          {{ data.item.age }}
        </template>

        <!-- Column: type -->
        <template #cell(role)="data">
          <b-badge
            pill
            :variant="tagsColor(data.item.user_role)"
            class="text-capitalize"
          >
            {{ data.item.user_role }}
          </b-badge>
        </template>

        <!-- Column: program -->
        <template #cell(program)="data">
          <b-badge variant="light-secondary">
            {{ data.value }}
          </b-badge>
        </template>

        <!-- Column: status -->
        <template #cell(status)="data">
          <b-badge
            v-if="data.item.is_accepted"
            variant="light-success"
          >
            {{ data.value }}
          </b-badge>
          <b-badge
            v-else
            :variant="`light-${resolveStatusVariant(data.value ? data.value.toLowerCase() : '')}`"
          >
            {{ data.value }}
          </b-badge>
        </template>

        <!-- Column: created_at -->
        <template #cell(created_at)="data">
          {{ data.item.created_at | longDate }}
        </template>

        <!-- Column: submitted_step_date -->
        <template #cell(submitted_step_date)="data">
          {{ data.item.submitted_step_date | longDate }}
        </template>

        <!-- Column: dob -->
        <template #cell(dob)="data">
          {{ data.item.birth_date }}
        </template>

        <!-- Column: groups -->
        <template #cell(groups)="data">
          {{ data.item.group_names }}
        </template>

        <!-- Column: semesters -->
        <template #cell(semesters)="data">
          {{ data.item.session_names }}
        </template>

        <!-- Column: tags -->
        <template #cell(tags)="data">
          <div class="d-flex justify-content-between">
            <badge-tag-list
              v-if="!$can('assign', permissionSubjects.Tags)"
              :tag-names="data.item.tags"
            />
            <badge-tag-list
              v-else-if="!data.detailsShowing"
              :tag-names="data.item.tags"
              class="cursor-pointer"
              @click.native="data.toggleDetails"
            />
            <tags-edit-selector
              v-else
              :application-id="data.item.id"
              :tags="data.item.tags"
              :tag-set-list="tagSetOptionsList"
              @tagsUpdated="updateTableItemTags(data.item.id, $event)"
              @closeEditing="data.toggleDetails"
            />
          </div>
        </template>

        <!-- Column: has_payment_failed -->
        <template #cell(has_payment_failed)="data">
          <b-badge
            v-if="data.item.has_payment_failed"
            variant="light-danger"
          >
            Failed
          </b-badge>
          <b-badge
            v-else-if="data.item.has_payments"
            variant="light-success"
          >
            Successful
          </b-badge>
          <b-badge
            v-else
            variant="light-primary"
          >
            Payment Pending
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">

          <!-- Dropdown -->
          <b-dropdown
            variant="link"
            toggle-class="mx-auto p-0"
            no-caret
            :right="$store.state.appConfig.isRTL"
            @show="setTableMinHeight"
            @hide="resetTableMinHeight"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              v-if="canLoginAsUser"
              @click="loginAs(data.item.user_id, data.item.user_role)"
            >
              <span>Log In as {{ userRole === 'Staff' ? 'Staff' :camperStudent(1) }}</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-for="option in actionOptions"
              :key="option.value"
              :to="{ name: option.link, params: { id: data.item.id, type: userRole } }"
              no-caret
            >
              <span>{{ option.title }}</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="canDeleteApplication"
              no-caret
              @click="showDeleteConfirmationModal(data.item)"
            >
              <span>Delete</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="checkIfCanMakePayment(data.item)"
              v-b-modal.add-payment
              no-caret
              @click="addPaymentData(data)"
            >
              <span>Make Payment</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-b-modal.generate-letter
              no-caret
              @click="generateLetterForApplication = data.item"
            >
              <span>Create Letter</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="canSendSingleCommunication"
              v-b-modal.single-communication-modal
              no-caret
              @click="applicationIdForCommunicate = data.item.id"
            >
              <span>Communicate</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-b-modal.application-sharing-modal
              no-caret
              @click="applicationIdForSharing = data.item.id"
            >
              <span>Share Application</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-if="totalItems && perPage !== 'all'"
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>

        <b-row>
          <b-col
            cols="12"
            class="d-flex align-items-center mt-2"
          >
            <applications-bulk-actions-buttons
              :marked-elements="markedElements"
              :btn-text="'Bulk Actions'"
              :use-caret="true"
              :variant="'outline-danger'"
            />
          </b-col>
        </b-row>
      </div>

    </b-card>

    <single-communicate-modal
      v-if="applicationIdForCommunicate"
      :is-bulk="false"
      :marked-elements="[applicationIdForCommunicate]"
      :filter-name="'application_id'"
      @successfullySend="singleCommunicateSuccess"
    />

    <application-sharing-modal
      v-if="applicationIdForSharing"
      :application-id="applicationIdForSharing"
      @sharingApplied="applicationIdForSharing = null"
    />

    <!-- Add Payment -->
    <PaymentPopup
      v-if="paymentData.userId"
      :popup-type="'add'"
      :user-id="paymentData.userId"
      :application-id="paymentData.applicationId"
      :card-id="paymentData.cardId"
      @paymentAdded="resetPaymentData"
    />
  </div>

</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable, BPagination, BFormCheckbox,
  BDropdown, BDropdownItem, BAvatar, BBadge, BLink, VBModal,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import { mapGetters } from 'vuex'
import { loginAs } from '@core/mixins/loginAs'
import { filters } from '@core/mixins/filters'
import store from '@/store'

import useApplicationsList from '@/views/admin/applications/applications-list/useApplicationsList'
import TableSpinner from '@/views/components/table-spinner/TableSpinner.vue'
import ViewCustomizer from '@/views/components/applications-viewing-options/ViewCustomizer.vue'
import BadgeTagList from '@/views/components/badge-tag-list/BadgeTagList.vue'
import SingleCommunicateModal from '@/views/components/bulk-communicate-modal/BulkCommunicateModal.vue'
import PaymentPopup from '@/views/components/paymentPopup/PaymentPopup.vue'
import ApplicationSharingModal from '@/views/components/application-sharing-modal/ApplicationSharingModal.vue'
import { permissionSubjects } from '@/libs/acl/constants'
import { DEFAULT_PER_PAGE } from '@/constants/base'
import setPerPage from '@/helpers/setPerPage'
import TagsEditSelector from '@/views/components/tags-edit-selector/TagsEditSelector.vue'
import ApplicationsBulkActionsButtons
  from '@/views/admin/applications/applications-list/components/ApplicationsBulkActionsButtons.vue'

export default {
  name: 'ApplicationsTableView',
  components: {
    ApplicationsBulkActionsButtons,
    BCard,
    BRow,
    BCol,
    BAvatar,
    BFormInput,
    BTable,
    BPagination,
    BFormCheckbox,
    BDropdown,
    BDropdownItem,
    BBadge,
    BLink,

    vSelect,
    TableSpinner,
    ViewCustomizer,
    BadgeTagList,
    SingleCommunicateModal,
    PaymentPopup,
    ApplicationSharingModal,
    TagsEditSelector,
  },
  directives: {
    'b-modal': VBModal,
  },
  mixins: [loginAs, filters],
  data() {
    return {
      paymentData: {
        userId: '',
        applicationId: '',
        cardId: null,
      },
      deletableApplicationId: null,
      confirmationModalHide: false,
      columns: [],
      selectedTableColumns: [],
      elementsPerPageCheckbox: false,
      isLoading: false,
      tableMinHeight: 'auto',
      permissionSubjects,
    }
  },
  setup(props, { root }) {
    const {
      fetchApplicationList,
      programId,
      tableColumns,
      tableItems,
      isTableLoading,
      perPage,
      perPageOptions,
      currentPage,
      totalItems,
      searchQuery,
      filterDataUserType,
      filterDataStatus,
      filterDataPaymentStatus,
      filterDataTags,
      filterDataStep,
      sortBy,
      dataMeta,
      isSortDirDesc,
      refApplicationsListTable,

      actionOptions,

      refetchData,

      resolveStatusVariant,
      clearFilters,
      updateApplicationStatuses,
    } = useApplicationsList(root)

    return {
      fetchApplicationList,
      programId,
      tableColumns,
      tableItems,
      isTableLoading,
      perPage,
      perPageOptions,
      currentPage,
      totalItems,
      sortBy,
      searchQuery,
      filterDataUserType,
      filterDataStatus,
      filterDataPaymentStatus,
      filterDataTags,
      filterDataStep,
      dataMeta,
      isSortDirDesc,
      refApplicationsListTable,

      actionOptions,

      refetchData,

      resolveStatusVariant,
      clearFilters,
      updateApplicationStatuses,
    }
  },
  computed: {
    ...mapGetters({
      boardViewType: 'app-applications/getBoardViewType',
      selectedViewColumns: 'app-applications/getSelectedViewColumns',
      forceReload: 'app-applications/getForceReload',
      selectedViewTagSets: 'app-applications/getSelectedViewTags',
      tagSetOptionsList: 'tags/getTagSetOptionsList',

    }),
    markedElements: {
      get() {
        return store.getters['app-applications/getMarkedElements']
      },
      set(val) {
        store.commit('app-applications/SET_MARKED_ELEMENTS', val)
      },
    },
    generateLetterForApplication: {
      get() {
        return store.getters['app-applications/getGenerateLetterForApplication']
      },
      set(val) {
        store.commit('app-applications/SET_GENERATE_LETTER_FOR_APPLICATION', val)
      },
    },
    applicationIdForCommunicate: {
      get() {
        return store.getters['app-applications/getApplicationIdForCommunicate']
      },
      set(val) {
        store.commit('app-applications/SET_APPLICATION_ID_FOR_COMMUNICATE', val)
      },
    },
    applicationIdForSharing: {
      get() {
        return store.getters['app-applications/getApplicationIdForSharing']
      },
      set(val) {
        store.commit('app-applications/SET_APPLICATION_ID_FOR_SHARING', val)
      },
    },
    userRole() {
      return this.$router.currentRoute.params.type
    },
    canDeleteApplication() {
      if (this.userRole === 'Staff') {
        return this.$can('delete', permissionSubjects.StaffApplication)
      }
      return this.$can('delete', permissionSubjects.ChildApplication)
    },
    canLoginAsUser() {
      if (this.userRole === 'Staff') {
        return this.$can('read', permissionSubjects.LoginAsStaff)
      }
      return this.$can('read', permissionSubjects.LoginAsChild)
    },
    canSendSingleEmailCommunication() {
      return this.$can('send', permissionSubjects.SingleEmailCommunication)
    },
    canSendSingleSmsCommunication() {
      return this.$can('send', permissionSubjects.SingleSMSCommunication)
    },
    canSendSingleCommunication() {
      return this.canSendSingleEmailCommunication || this.canSendSingleSmsCommunication
    },
  },
  watch: {
    dataMeta() {
      this.$nextTick(() => {
        this.handleElementsPerPageCheckbox()
      })
    },
    programId() {
      this.refetchData()
    },
    forceReload() {
      this.refetchData()
    },
    $route() {
      this.setFilterParams()
      this.refetchData()
    },
    selectedViewTagSets() {
      this.setFilterParams()
      this.refetchData()
    },
  },
  mounted() {
    this.setFilterParams()
    // Update filters
    window.onpopstate = () => {
      this.setFilterParams()
    }
    this.fetchApplicationList()
    this.fetchTagSetsListOptions()
  },
  methods: {
    sortingChanged(ctx) {
      this.isSortDirDesc = ctx.sortDesc
    },
    checkIfCanMakePayment(item) {
      return this.$can('add', permissionSubjects.GroupMember) && (item.is_accepted || item.status === 'Payment Failed')
    },
    addPaymentData(data) {
      this.paymentData.userId = data.item.user_id
      this.paymentData.applicationId = data.item.id
      this.paymentData.cardId = data.item.card_id
    },
    resetPaymentData() {
      this.paymentData = {
        userID: '',
        applicationId: '',
        cardId: null,
      }
    },
    updatePerPage(val) {
      localStorage.setItem('applicationsPerPage', val)
      this.perPage = val
    },
    tagsColor(tag) {
      if (tag.toLowercase() === 'staff') return 'light-primary'
      if (tag.toLowercase() === 'child') return 'light-info'
      return 'light-primary'
    },
    filterTags(tags) {
      const availableViewTags = this.selectedViewTagSets.map(tagSet => tagSet.id)

      if (availableViewTags.length > 0) {
        return tags
          .filter(tag => availableViewTags.includes(tag.tag_set_id))
      }
      return tags
    },
    setFilterParams() {
      const query = { ...this.$route.query }
      const defaultPerPage = localStorage.getItem('applicationsPerPage')
      this.currentPage = Number(query.currentPage) || 1
      this.perPage = setPerPage(query.perPage, defaultPerPage, DEFAULT_PER_PAGE)
      this.searchQuery = query.searchQuery || ''
      this.filterDataUserType = query.filterDataUserType || ''
      this.filterDataStatus = query.filterDataStatus || ''
      this.filterDataPaymentStatus = query.filterDataPaymentStatus || ''
      const filterDataStep = query.filterDataStep || ''
      store.commit('app-applications/SET_FILTER_DATA_STEP', filterDataStep)
    },
    markElementsOnPage(val) {
      const elements = this.markedElements
      if (val) {
        this.refApplicationsListTable.localItems.forEach(application => {
          const index = elements.indexOf(application.id)
          if (index < 0) {
            elements.push(application.id)
          }
        })
      } else {
        this.refApplicationsListTable.localItems.forEach(application => {
          const index = elements.indexOf(application.id)
          if (index >= 0) {
            elements.splice(index, 1)
          }
        })
      }
      this.markedElements = elements
    },
    handleElementsPerPageCheckbox() {
      let counter = 0
      this.refApplicationsListTable.localItems.forEach(application => {
        if (this.markedElements.indexOf(application.id) !== -1) {
          counter += 1
        }
      })

      this.elementsPerPageCheckbox = !!(this.refApplicationsListTable.localItems && counter === this.refApplicationsListTable.localItems.length)
    },
    singleCommunicateSuccess() {
      this.applicationIdForCommunicate = null
      this.$bvModal.hide('single-communication-modal')
    },
    setTableMinHeight() {
      this.tableMinHeight = '270px'
    },
    resetTableMinHeight() {
      this.tableMinHeight = 'auto'
    },
    showDeleteConfirmationModal(application) {
      this.$store.commit('app-applications/SET_DELETABLE_APPLICATION', application)
      this.$nextTick(() => {
        this.$bvModal.show('confirmation')
      })
    },
    async fetchTagSetsListOptions() {
      await this.$store.dispatch('tags/prepareTagSetsListOptions', { programId: this.programId })
    },
    updateTableItemTags(id, tags) {
      const item = this.tableItems.find(item => item.id === id)
      if (item) item.tags = tags
      this.$forceUpdate()
    },
  },
}
</script>

<style lang="scss" scoped>
  .table-header {

    label {
      margin-bottom: 0;
    }
  }

  .badge {
    text-transform: uppercase;
  }

  .per-page-selector {
    width: 90px;
  }

  .invoice-filter-select {
    min-width: 190px;

    ::v-deep .vs__selected-options {
      flex-wrap: nowrap;
    }

    ::v-deep .vs__selected {
      width: 100px;
    }
  }
</style>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  .name-col {
    min-width: 200px;
  }
</style>
